.bubble-chat {
  position: absolute;
  // z-index: 3;
  z-index: 1;
  bottom: 10px;
  right: 10px;
  display: flex;
  animation-name: animationHide;
  animation-duration: 0.5s;

  p{
    font-family: "lato";
  }

  svg.bubble {
    fill: #000000;
    width: 80px;
    height: 80px;
    cursor: pointer;
    path.inner {
      fill: rgb(245 197 31);
      stroke-width: 2;
    }
    path.outter {
      fill: none;
      stroke: rgb(27 27 27);
      stroke-linecap: round;
      stroke-linejoin: round;
      stroke-width: 1;
    }

    g.hands-shake {
      transform: translate(50%, 50%) scale(0.5) translate(-50%, -50%);
      .movement-line {
        opacity: 1;
        stroke: #1c274c;
        stroke-width: 1.5;
        stroke-linecap: round;
        fill: #b99521;
      }
      &:hover {
        transform: translate(50%, 50%) scale(0.5) rotate(-15deg) translate(-50%, -50%);
      }
    }
  }

  @keyframes animationHide {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  .bubble-chat__me {
    height: 150px;
    filter: drop-shadow(-5px -5px 0px #f5c51f);
    cursor: pointer;
  }

  .bubble-chat__bubble {
    // transform: translateX(-500px) translateY(-600px);
    z-index: 999999999999999;
    width: 60px;
    height: 60px;
    position: absolute;
    bottom: 20px;
    right: 20px;
    cursor: pointer;
    background-color: white;
    border-radius: 100%;
    display: grid;
    border: solid 1px #fbbd08;
    box-shadow: 0px 0px 12px 5px #fbbd085c;

    animation-name: animationMove;
    animation-duration: 4s;

    @keyframes animationMove {
      30% {
        right: 17px;
        bottom: 22px;
      }
      35% {
        right: 22px;
        bottom: 18px;
      }
      40% {
        right: 16px;
        bottom: 18px;
      }
      45% {
        right: 18px;
        bottom: 22px;
      }
      50% {
        right: 22px;
        bottom: 16px;
      }
      55% {
        right: 18px;
        bottom: 23px;
      }
      60% {
        right: 22px;
        bottom: 17px;
      }
      65% {
        right: 17px;
        bottom: 22px;
      }
      70% {
        right: 22px;
        bottom: 18px;
      }
      75% {
        right: 16px;
        bottom: 18px;
      }
      80% {
        right: 18px;
        bottom: 22px;
      }
      80% {
        right: 18px;
        bottom: 17px;
      }
      90% {
        right: 23px;
        bottom: 18px;
      }
      95% {
        right: 19px;
        bottom: 21px;
      }
      100% {
        right: 20px;
        bottom: 20px;
      }
    }

    .cursor_image {
      img {
        max-width: 100%;
        border-radius: 50%;
        position: absolute;
        width: max-content;
        height: -webkit-fill-available;
        object-fit: fill;
      }
    }
  }
}
