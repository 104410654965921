.further-action {
  position: absolute;
  bottom: 0%;
  right: 10%;
  display: flex;
  gap: 0px;
  flex-direction: column-reverse;
  z-index: 2;
  align-items: center;

  p {
    margin-bottom: 6px;
    margin-top: -9px;
  }

  svg.btn-back,
  svg.btn-love {
    margin: auto;
    cursor: pointer;
    .outter {
      fill: #666;
      stroke: #222;
      stroke-width: 3;
    }
    .inner {
      fill: #999;
      stroke: #ccc;
      stroke-width: 3;
    }
    .arrow-1 {
      fill: #b0cad7;
      stroke: #222;
      stroke-width: 2.5;
    }
    .arrow-2 {
      fill: #c9dfe8;
      stroke: #222;
      stroke-width: 2.5;
    }
    .inner,
    .arrow-1,
    .arrow-2 {
      transition: transform 0.2s ease-in-out;
    }
    text {
      font-size: 56px;
      font-family: "graduate";
      fill: #0e0429;
    }
    &:hover,
    &.clicked {
      .inner,
      .arrow-1,
      .arrow-2 {
        // fill: #999;
        transform: translate(50%, 50%) scale(0.9) translate(-50%, -50%);
      }
    }
    &.clicked {
      .outter {
        fill: #222;
      }
      .inner {
        transform: translate(50%, 50%) scale(0.95) translate(-50%, -50%);
      }
    }
  }

  svg.btn-love {
    svg.heart {
      .st0 {
        transition: transform 0.2s ease-in-out;
        fill: #fff;
        transform: translate(50%, 50%) scale(0.8) translate(-50%, -50%);
      }
    }
  }
  svg.btn-love.clicked {
    svg.heart {
      path.st0 {
        transform: translate(50%, 50%) scale(1.1) translate(-50%, -50%);
      }
      .st1 {
        fill: #ed5168;
      }
    }
  }
}
