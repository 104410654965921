// .social-media-wrapper {

svg {
  &#ws__svg__original__title {
    text {
      &.element-text.header {
        filter: drop-shadow(-2px -2px 0px #b79319);
        font-family: "Graduate";
        font-variant-caps: small-caps;
        // stroke: #b79319;
        // stroke: rgb(209 175 59);
      }
    }
  }
  &#ws__svg__original__monitor {
    g.contact-form {
      display: none;
    }
    &.rendering-monitor,
    &.rendering-text-and-monitor,
    &.rendering-main-words {
      .g-monitorTabMiro,
      .g-monitorTabMiroDetailTop {
        transform: translate(-155px, -10px);
      }
    }

    // &.rendering-end {
    //   .g-monitorTabMiro,
    //   .g-monitorTabMiroDetailTop {
    //     transform: none;
    //   }
    // }

    image {
      &.miro-idea {
        display: none;
      }
    }

    .g-monitorTabNotion {
      transition: transform 0.5s linear;
    }
    .monitorTabMusic {
      transition: transform 1s linear;
    }

    .g-monitorTabGoogle,
    .g-monitorTabMusic {
      image {
        transition: opacity 0.5s linear, visibility 0.5s ease-out;
        opacity: 0;
        visibility: hidden;
      }
    }

    &.scene-about-me {
      image {
        &.miro-idea {
          display: block;
        }
      }
    }

    &.scene-contact,
    &.scene-my-skills.rendering-monitor {
      g.g-monitorTabNotion {
        image {
          opacity: 1;
          visibility: visible;
        }
        path.monitorTabNotion {
          fill: #1b1b1b;
        }
      }
      g.g-monitorTabGoogle {
        image {
          opacity: 1;
          visibility: visible;
        }
        path.monitorTabGoogle {
          fill: #0679cc;
        }
      }
      g.g-monitorTabMusic {
        image {
          opacity: 1;
          visibility: visible;
        }
        path.monitorTabMusic {
          fill: white;
        }
      }
    }
    &.scene-contact,
    &.scene-my-skills {
      g.g-monitorTabMusic {
        transform: translate(-30px, -152px);
      }
      g.g-monitorTabNotion {
        transform: translate(-322px, 33px);
      }
      g.g-monitorTabGoogle {
        transform: translate(-200px, 32px);
      }
    }

    &.scene-contact.rendering-end {
      g.g-monitorTabMusic,
          // g.g-monitorTabNotion,
          g.g-monitorTabGoogle {
        display: none;
      }
      g.g-monitorTabNotion {
        .element-text.p.monitorTabNotion {
          font-family: "Roboto Mono";
          font-size: 20px !important;
        }
      }

      g.g-monitorMain {
        opacity: 1;
        visibility: visible;

        path {
          fill: rgb(211 211 211);
        }

        text {
          font-family: "lato";
          fill: rgb(0 0 0);

          &.element-text.p {
            transform: translate(0px, 138px) !important;
            textPath {
              font-size: 22px;
            }
          }

          &.element-text.header {
            user-select: all;
          }
        }

        g.contact-form {
          display: block;
          foreignObject {
            transform: translate(110px, 30px) rotate(-8deg);
            width: 320px;
            font-size: 22px;
            font-family: "Roboto Mono";

            input,
            textarea {
              height: 100%;
              width: 100%;
              font-family: "Roboto Mono";
              font-variant-caps: all-small-caps;

              font-size: large;
              padding: 5px;
              padding-bottom: 8px;
            }
            textarea {
              width: 100%;
              font-size: large;
              padding-bottom: 5px;
            }
            &.text-area-content {
              height: 100px;
            }

            svg.btn-send {
              margin: auto;
              cursor: pointer;
              .outter {
                fill: #666;
                stroke: #222;
                stroke-width: 3;
              }
              .inner {
                fill: #999;
                stroke: #ccc;
                stroke-width: 3;
              }

              .inner {
                transition: transform 0.2s ease-in-out;
              }
              text {
                font-size: 46px;
                fill: #0e0429;
                font-variant-caps: all-small-caps;
              }
              &:hover,
              &.clicked {
                .inner {
                  transform: translate(50%, 50%) scale(0.9) translate(-50%, -50%);
                }
              }
              &.clicked {
                .outter {
                  fill: #222;
                }
                .inner {
                  transform: translate(50%, 50%) scale(0.95) translate(-50%, -50%);
                }
              }
            }
          }
        }
      }

      g.g-monitorTabNotion {
        path.monitorTabNotion {
          fill: whitesmoke;
        }
        text {
          textPath {
            fill: black;
          }
        }
      }
    }
  }

  &#ws__svg__three-screens__computer {
    transition: right 1s ease-in-out, bottom 1s ease-in-out, width 1s ease-in-out, transform 1s ease-in-out;
    position: absolute;
    bottom: -50%;
    right: -50%;
    width: 50%;
    filter: drop-shadow(-64px 56px 41px rgb(255 255 255 / 30%));

    .g-computer {
      transition: opacity 0.5s linear, visibility 0.5s ease-out;
      opacity: 0;
      visibility: hidden;
    }

    &.scene-contact,
    &.scene-my-skills.rendering-monitor {
      g.g-computer {
        opacity: 1;
        visibility: visible;
        path.computer {
          fill: white;
        }
      }
    }
    &.scene-contact {
      g.g-computer {
        path.computer {
          fill: black;
        }
        image.computer {
          display: none;
        }
      }
    }
  }
  &#ws__svg__three-screens__ipad {
    transition: left 1s ease-in-out, bottom 1s ease-in-out, width 1s ease-in-out, transform 1s ease-in-out;
    filter: drop-shadow(64px 56px 41px rgb(255 255 255 / 30%));

    position: absolute;
    left: -50%;
    bottom: 0;
    width: 48%;
    &.scene-my-skills,
    &.scene-contact {
      &.rendering-monitor,
      &.rendering-end,
      &.rendering-end-intro {
        path.ipad {
          fill: white;
        }
      }
    }
    &.scene-contact.rendering-end {
      .g-ipad {
        image.ipad {
          transform: translate(-21px, 784px) rotate(-6.107837deg) translate(-7px, -926px) scale(1.15) !important;
        }
      }
    }
  }
}

.post {
  svg {
    &#ws__svg__original__title {
      width: 100%;
      position: absolute;
      top: 50px;
      left: 0%;
      transform: rotate(0.4deg);
      transition: transform 1s ease-in-out, width 1s ease-in-out;
      &.rendering-main-words {
        transform: rotate(-2deg);
      }
      &.rendering-text-and-monitor {
        width: 85%;
        left: 5%;
      }
    }
    &#ws__svg__original__monitor {
      transition: transform 1s ease-in-out, right 1s ease-in-out, bottom 1s ease-in-out, width 1s ease-in-out;

      width: 110%;
      position: absolute;
      right: -61%;
      bottom: -5%;
      transform: rotate(15deg);
      // filter: drop-shadow(40px -30px 177px #d5cba3); // for the dark mode  color: #d5cba3
      filter: drop-shadow(-64px 56px 41px white); // for the dark mode  color: #d5cba3
      &.rendering-main-words {
        right: -68%;
      }
      &.rendering-monitor,
      &.rendering-text-and-monitor {
        width: 94%;
        right: 3%;
        transform: rotate(11deg);
      }
      &.rendering-monitor {
        bottom: 15%;
      }
      &.rendering-text-and-monitor {
        bottom: 3%;
      }
      // &.rendering-end {
      //   width: 132%;
      //   right: 4%;
      //   bottom: 16%;
      //   transform: rotate(8deg);
      // }

      &.scene-contact,
      &.scene-my-skills.rendering-monitor {
        transform: rotate(7deg);
        right: 8%;
        bottom: 25%;
        width: 94%;

        g.monitorTabMiro,
        g.g-monitorTabMiroDetailTop {
          display: none;
        }
      }

      &.scene-contact {
        bottom: 33%;
      }
    }
    &#ws__svg__ignacio-front {
      transition: left 1s ease-in-out, right 1s ease-in-out, bottom 1s ease-in-out, width 1s ease-in-out;

      width: 74%;
      left: -131px;
      bottom: -65px;
      // filter: drop-shadow(-140px -40px 180px #d5cba3); // for the dark mode  color: #d5cba3
      // filter: drop-shadow(-140px -40px 96px whitesmoke);

      // TO BE REFACtOR HERE: it is story knowladge not related with the section.

      &.scene-my-skills,
      &.scene-contact {
        &.rendering-main-words,
        &.rendering-monitor,
        &.rendering-end-intro,
        &.rendering-end {
          width: 38%;
          left: -30%;
          bottom: -40%;
        }
      }

      &.rendering-main-words {
        left: 10%;
        width: 60%;
        bottom: -20%;
      }

      &.rendering-monitor {
        width: 38%;
        left: -10%;
        bottom: -10%;
        // filter: drop-shadow(-140px -40px 96px rgb(255 255 255 / 50%));
      }

      &.rendering-text-and-monitor {
        width: 40%;
        left: 10%;
        bottom: -10%;
        // filter: drop-shadow(-64px 56px 41px rgb(255 255 255 / 30%));
        filter: none;
      }
      // &.rendering-end {
      //   width: 33%;
      //   left: -11%;
      //   bottom: -10%;
      //   filter: none;
      // }
    }
    &#ws__svg__three-screens__computer {
      // &.scene-my-skills,
      &.scene-contact {
        bottom: 3%;
        right: 3%;
        width: 41%;
        transform: rotate(5deg);
        &.rendering-end {
          bottom: -50%;
          right: -50%;
        }
      }
      &.scene-my-skills.rendering-monitor {
        bottom: 0%;
        right: 0%;
        width: 41%;
        transform: rotate(5deg);
      }
    }
    &#ws__svg__three-screens__ipad {
      &.scene-my-skills.rendering-monitor {
        left: 0;
        bottom: -5%;
        width: 38%;
      }

      &.scene-contact {
        left: 0;
        bottom: 0;
        width: 38%;
      }
    }
  }
}

.reel {
  svg {
    &#ws__svg__original__title {
      transition: transform 1s ease-in-out;
      position: absolute;
      width: 130%;
      left: 0%;

      &.rendering-end {
        transform: rotate(0deg);
      }
    }
    &#ws__svg__original__monitor {
      transition: left 1s ease-in-out, right 1s ease-in-out, bottom 1s ease-in-out, width 1s ease-in-out,
        transform 1s ease-in-out;
      position: absolute;
      width: 156%;

      &.rendering-main-words {
        width: 180%;
        bottom: 2%;
        left: 60%;

        &.scene-my-skills {
          left: 5%;
          bottom: 0%;
        }
      }
      &.rendering-monitor {
        width: 174%;
        left: -71%;
        bottom: 15%;
      }

      &.scene-my-skills.rendering-monitor,
      &.scene-contact {
        width: 168%;
        left: -68%;
        bottom: 26%;
        g.g-monitorTabMiro,
        g.g-monitorTabMiroDetailTop {
          display: none;
        }
        &.rendering-end,
        &.rendering-end-intro {
          bottom: 35%;
          transform: rotate(7deg);
        }
      }

      &.rendering-text-and-monitor {
        width: 151%;
        left: -49%;
        bottom: 4%;
      }

      &.scene-my-skills,
      &.scene-contact {
        &.rendering-end {
          g.g-monitorTabNotion {
            display: none;
          }
        }
        g.g-monitorTabNotion {
          transform: translate(-80px, 14px);
        }
        g.g-monitorTabGoogle {
          transform: translate(0px, -13px);
        }
        g.g-monitorTabMusic {
          transform: translate(0px, 0px);
        }
      }
    }
    &#ws__svg__ignacio-front {
      transition: left 1s ease-in-out, right 1s ease-in-out, bottom 1s ease-in-out, width 1s ease-in-out;
      width: 118%;
      right: -20%;
      bottom: 0%;
      left: auto;

      &.rendering-main-words {
        right: 20%;
        bottom: -20%;
      }

      &.rendering-monitor,
      &.rendering-text-and-monitor {
      }
      &.rendering-monitor {
        width: 101%;
        right: -13%;
        bottom: -20%;
      }
      &.rendering-text-and-monitor {
        width: 65%;
        right: -13%;
        bottom: -10%;
      }
      &.rendering-end {
        width: 50%;
        right: 55%;
        bottom: -10%;
      }

      &.scene-my-skills,
      &.scene-contact {
        bottom: -70%;
      }
    }
    &#ws__svg__three-screens__computer {
      bottom: -40%;
      right: -40%;
      width: 70%;
      &.scene-my-skills.rendering-monitor,
      &.scene-contact {
        bottom: 1%;
        right: -6%;
        width: 63%;
        &.rendering-end-intro {
          bottom: 6%;
          right: -5%;
          width: 63%;
          transform: rotate(2deg);
        }
        &.rendering-end {
          transform: rotate(2deg);
          bottom: -40%;
          right: -40%;
        }
      }
    }
    &#ws__svg__three-screens__ipad {
      left: -50%;
      width: 48%;
      bottom: 0;
      &.scene-my-skills.rendering-monitor {
        filter: none;
      }
      &.scene-my-skills.rendering-monitor,
      &.scene-contact {
        left: 0;
        bottom: 3%;
        width: 43%;
        &.rendering-end,
        &.rendering-end-intro {
          bottom: 0%;
          width: 46%;
        }
        &.rendering-end-intro {
          filter: none;
        }
        &.rendering-end {
          width: 63%;
        }
      }
    }
  }
}

.desktop {
  svg {
    &#ws__svg__original__title {
      transition: transform 1s ease-in-out;
      // transform: translate(-10%, -8%);
      &.rendering-main-words {
        // width: 100%;
      }
      &.rendering-text-and-monitor {
        transform: rotate(0deg) translate(-70px, -57px) scale(0.85);
      }
    }
    &#ws__svg__original__monitor {
      transition: left 1s ease-in-out, right 1s ease-in-out, bottom 1s ease-in-out, width 1s ease-in-out,
        transform 1s ease-in-out;
      position: absolute;
      &.rendering-main-words {
        width: 100%;
        right: 22px;
        bottom: -52%;
      }
      &.rendering-monitor {
        width: 80%;
        right: 21%;
        bottom: 0;
      }
      &.rendering-text-and-monitor {
        width: 60%;
        right: 30%;
        bottom: -3%;
      }
      &.rendering-end {
        right: 8%;
        bottom: 3%;
        width: 90%;
      }

      &.scene-my-skills.rendering-main-words {
        width: 90%;
        right: -47%;
        bottom: 10%;
      }

      &.scene-my-skills.rendering-monitor,
      &.scene-contact {
        width: 70%;
        right: 18%;
        bottom: 15%;
        transform: rotate(8deg);
        g.monitorTabMiro,
        g.g-monitorTabMiroDetailTop {
          display: none;
        }
      }
      &.scene-contact {
        bottom: 18%;
      }
    }
    &#ws__svg__ignacio-front {
      image.character-intro {
        transform: translateY(18%);
      }
      transition: left 1s ease-in-out, right 1s ease-in-out, bottom 1s ease-in-out, width 1s ease-in-out;
      width: 50%;
      &.rendering-main-words {
        width: 50%;
        right: 4%;
      }
      &.scene-my-skills,
      &.scene-contact {
        bottom: -100%;
      }
      &.rendering-monitor,
      &.rendering-text-and-monitor {
        width: 40%;
        right: -2%;
      }
    }
    &#ws__svg__three-screens__computer {
      &.scene-my-skills.rendering-monitor {
        bottom: 0;
        right: 0;
        width: 30%;
      }
      &.scene-contact {
        bottom: 0;
        right: 0;
        width: 30%;
        transform: rotate(5deg);
        &.rendering-end {
          bottom: -50%;
          right: -50%;
        }
      }
    }
    &#ws__svg__three-screens__ipad {
      &.scene-my-skills.rendering-monitor {
        left: 0;
        width: 26%;
      }
      &.scene-contact {
        left: 0;
        width: 26%;
        transform: rotate(-5deg);
      }
    }
  }
}
