.img-frame {
  height: 100vh;
  width: 100vw;
  .text__title {
    &.main {
      background: blanchedalmond;
      top: 5%;
      border: solid 2px;
      border-color: #934e19;
      z-index: 2;
      left: 100px;

      // @media only screen and (max-width: 600px) {
      //   left: -15px;
      //   top: 20px;
      //   transform: scale(0.8);
      // }
    }
  }
  .def-elements {
    transform: rotate3d(-2, 7, 2, -16deg);
    position: relative;
    padding: 80px;
    overflow: hidden;
    height: 100%;

    &.defining-point {
      cursor: crosshair;
    }

    // image {
    //   background: transparent;
    //   position: absolute;
    //   left: 0px;
    //   top: 0px;
    //   width: 100%;

    //   &.character-image {
    //     width: auto;
    //   }

    //   &.img_ai_generated_1024x1024 {
    //   }

    //   &.computer-desktop {
    //     transform: translateX(111px) translateY(845px) scale(0.39) rotate(-6deg);
    //   }

    //   &.deltaY300 {
    //     transform: translateY(300px);
    //   }

    //   &.hidden {
    //     display: none;
    //   }

    //   -webkit-animation: fadeInFromNone 2s ease-out;
    //   -moz-animation: fadeInFromNone 2s ease-out;
    //   -o-animation: fadeInFromNone 2s ease-out;
    //   animation: fadeInFromNone 2s ease-out;

    //   @-webkit-keyframes fadeInFromNone {
    //     0% {
    //       display: none;
    //       opacity: 0;
    //     }

    //     1% {
    //       display: block;
    //       opacity: 0;
    //     }

    //     100% {
    //       display: block;
    //       opacity: 1;
    //     }
    //   }

    //   @-moz-keyframes fadeInFromNone {
    //     0% {
    //       display: none;
    //       opacity: 0;
    //     }

    //     1% {
    //       display: block;
    //       opacity: 0;
    //     }

    //     100% {
    //       display: block;
    //       opacity: 1;
    //     }
    //   }

    //   @-o-keyframes fadeInFromNone {
    //     0% {
    //       display: none;
    //       opacity: 0;
    //     }

    //     1% {
    //       display: block;
    //       opacity: 0;
    //     }

    //     100% {
    //       display: block;
    //       opacity: 1;
    //     }
    //   }

    //   @keyframes fadeInFromNone {
    //     0% {
    //       display: none;
    //       opacity: 0;
    //     }

    //     1% {
    //       display: block;
    //       opacity: 0;
    //     }

    //     100% {
    //       display: block;
    //       opacity: 1;
    //     }
    //   }

    //   // &.fade-animation {
    //   //   opacity: 0;
    //   //   transition: opacity 1s;
    //   // }

    //   // @keyframes fade {
    //   //   from {
    //   //     opacity: 1;
    //   //   }

    //   //   to {
    //   //     opacity: 0;
    //   //   }
    //   // }
    // }

    svg.img-frame__svg,
    img {
      border: 2px solid black;
      width: 520px;
      position: absolute;
      left: 80px;
      top: 80px;
    }

    svg.img-frame__svg {
      // filter: drop-shadow(-16px -13px 6px rgba(0, 0, 0, 0.4));
      // filter: drop-shadow(-38px -34px 12px rgba(0, 0, 0, 0.4));
      filter: drop-shadow(-38px 9px 12px rgba(0, 0, 0, 0.4));
      // background: rgb(115 113 113);
      transform: translate3d(0, 0, 0);

      // @media only screen and (max-width: 600px) {
      //   filter: none;
      //   border: none;
      // }

      // background: #00000042;
      circle {
        cursor: pointer;

        &.point {
          &:hover {
            r: 6px;
          }

          &.mode-editing-on {
            opacity: 0.2;
            r: 1px;
            &.selected {
              r: 8px;
              fill: transparent;
              opacity: 0.4;
              stroke: rgba(4, 244, 4);
            }
          }
        }
      }
    }

    svg {
      &.slider-right,
      &.slider-left {
        position: absolute;
        bottom: 100px;

        polyline {
          &:hover {
            cursor: pointer;
            stroke-width: 2;
          }
        }
      }
      &.slider-right {
        transform: rotate(-30deg);
        right: 100px;
        // @media only screen and (max-width: 600px) {
        //   transform: rotate(-30deg) scale(0.6);
        //   right: -10px;
        //   bottom: 300px;
        // }
      }
      &.slider-left {
        transform: rotate(150deg);
        left: 100px;
        // @media only screen and (max-width: 600px) {
        //   transform: rotate(150deg) scale(0.6);
        //   left: -10px;
        //   bottom: 290px;
        // }
      }
    }

    // @media only screen and (max-width: 600px) {
    //   // padding: 25px 0px 70px 0px;
    //   padding: 0px;
    //   display: flex;
    //   transform: none;
    //   width: 100vw;
    //   border: none;
    // }
  }
}
