.bubble-chat__chat {
  $height: calc(100% - 200px);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  z-index: 0;
  position: fixed;
  text-align: left;

  // bottom: 100px;
  // right: 20px;

  bottom: 100px;
  right: 100px;

  width: 576px;
  max-height: 704px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 5px 40px;
  height: $height;
  border-radius: 8px;
  background-color: white;
  animation-name: animatiomHeight;
  animation-duration: 1s;

  visibility: visible;
  opacity: 1;
  transition: visibility 0.5s, opacity 0.5s, height 0.5s linear;

  &.hidden {
    height: calc(0.8 * $height);
    visibility: hidden;
    opacity: 0;
  }

  @keyframes animatiomHeight {
    from {
      height: calc(0.8 * $height);
    }
    to {
      height: $height;
    }
  }

  .chat__header {
    display: flex;
    border-radius: 8px 8px 0px 0px;
    min-height: 75px;
    transition: height 160ms ease-out 0s;
    //background: linear-gradient(135deg, rgb(255, 255, 255) 0%, rgb(204, 204, 204) 100%);
    background: linear-gradient(135deg, rgb(244 182 12) 0%, #fcd80b87 100%);

    .profile_description {
      margin: auto 0;
      padding: 8px;
      color: white;
      width: 100%;
      background: #fee48940;
    }

    .chat__header_close {
      position: absolute;
      right: 0px;
    }

    .wrapper-online {
      position: absolute;
      right: 10px;
      top: 35px;
      display: flex;
      padding: 6px;
      // background: #f5c13787;
      color: #f5b717;
      font-weight: bold;
    }

    i {
      cursor: pointer;
      float: right;
      margin: 10px 5px;
      color: brown;
    }
    img {
      height: 88px;
      margin: 0px 10px -10px 0px;
    }
  }

  .chat__body {
    padding: 20px;
    overflow: auto;
    height: -webkit-fill-available;

    .msg-img {
      width: 40px;
      height: 40px;
      margin: auto 0;
      border-radius: 50%;
    }
    .chat__msg-wrapper {
      position: relative;
      display: flex;
      width: 100%;
      &.right {
        display: inline-block;
      }
      .chat__msg {
        position: relative;
        font-size: initial;
        margin: 5px;
        margin-left: 15px;
        padding: 10px 15px;
        border-radius: 5px;
        display: inline-block;
        width: auto;
        max-width: 75%;
        background-color: rgb(245, 245, 245);
        &.right {
          text-align: right;
          float: right;
          background-color: #d8915f;
          cursor: pointer;
        }
      }

      .msg-img {
        margin-top: 0;
        transition: all 1000ms;
      }
    }
  }

  // @media only screen and (max-width: 600px) {
  //   right: 0;
  //   width: 100vw;
  //   bottom: 110px;
  //   height: calc(100% - 230px);
  // }
}

.dot-typing {
  position: relative;
  left: -9990px;
  width: 8px;
  height: 8px;
  border-radius: 5px;
  background-color: #d2bd9d;
  color: #d2bd9d;
  box-shadow: 9984px 0 0 0 #d2bd9d, 9999px 0 0 0 #d2bd9d, 10014px 0 0 0 #d2bd9d;
  animation: dotTyping 1.5s infinite linear;
}

@keyframes dotTyping {
  0% {
    box-shadow: 9984px 0 0 0 #d2bd9d, 9999px 0 0 0 #d2bd9d, 10014px 0 0 0 #d2bd9d;
  }
  16.667% {
    box-shadow: 9984px -10px 0 0 #d2bd9d, 9999px 0 0 0 #d2bd9d, 10014px 0 0 0 #d2bd9d;
  }
  33.333% {
    box-shadow: 9984px 0 0 0 #d2bd9d, 9999px 0 0 0 #d2bd9d, 10014px 0 0 0 #d2bd9d;
  }
  50% {
    box-shadow: 9984px 0 0 0 #d2bd9d, 9999px -10px 0 0 #d2bd9d, 10014px 0 0 0 #d2bd9d;
  }
  66.667% {
    box-shadow: 9984px 0 0 0 #d2bd9d, 9999px 0 0 0 #d2bd9d, 10014px 0 0 0 #d2bd9d;
  }
  83.333% {
    box-shadow: 9984px 0 0 0 #d2bd9d, 9999px 0 0 0 #d2bd9d, 10014px -10px 0 0 #d2bd9d;
  }
  100% {
    box-shadow: 9984px 0 0 0 #d2bd9d, 9999px 0 0 0 #d2bd9d, 10014px 0 0 0 #d2bd9d;
  }
}

.chat__action-wrapper.input-model {
  position: absolute;
  bottom: 0;
  width: 100%;
  left: 0;
  display: contents;
  .ui.action.input {
    width: -webkit-fill-available;
    button {
      &.button {
        border-radius: 0px 0px 5px;
      }
    }
  }
  input {
    background: #8a5b311c;
    padding: 15px;
    border-bottom: none;
    border-radius: 0px 10px;
    border-left: none;
    border-right: none;
    &:focus {
      border-color: #f6bd2b;
    }
  }
}
