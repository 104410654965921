.character-profile {
  display: flex;
  .img-profile {
    position: absolute;
    bottom: 0;
    height: 300px;
    filter: drop-shadow(5px -5px 0px #f5c51f);
  }

  &.full {
    .img-profile {
      height: 100vh;
    }
  }

  &.small {
    .img-profile {
      height: 150px;
    }
  }

  &.bottom-right {
    .img-profile {
      right: 0;
    }
  }

  &.bottom-left {
    .img-profile {
      left: 0;
    }
  }

  &.bottom-center {
    .img-profile {
      left: 50%;
      transform: translate(-50%, 0%);
    }
  }

  &.center {
    .img-profile {
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }
}
