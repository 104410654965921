.text {
  color: #493215;
  font-family: "Gloria Hallelujah", cursive;
  line-height: 1.2;
}
.text-svg {
  position: relative;
  .text_layer-white {
    width: 100%;
    height: calc(100% + 15px);
    color: white;
    position: absolute;
    background: rgba(255, 255, 255, 0.7);
    bottom: -15px;
  }

  text {
    color: #493215;
    font-family: "Gloria Hallelujah", cursive;
    line-height: 1.2;
  }

  &.text {
    text {
      // font-size: 2rem;
      font-size: 1.8rem;
    }
  }
  &.title {
    margin-bottom: 30px;
    text {
      font-size: 3rem;
    }
  }
  &.ta-right {
    text-align: right;
  }
  &.ta-left {
    text-align: left;
  }
}

.text__title {
  h1 {
    font-size: 38px;
    // max-width: 300px;
    display: table;
    position: relative;
  }
  &.ta-right {
    h1 {
      text-align: right;
    }
  }
  &.ta-left {
    h1 {
      text-align: left;
    }
  }

  .svg__title_underline-line {
    position: absolute;
    left: 0;
    top: 0;
    // filter: drop-shadow(2px 2px 4px white);
    path {
      fill: transparent;
      stroke: #fbbd08;
      stroke-width: 3;
    }
  }

  &.main {
    position: absolute;
    // z-index: 0;
    // left: 148px;
    // top: 30px;
    left: 128px;
    top: 24px;
    transform: rotate3d(-2, 7, 2, -16deg);
    // filter: drop-shadow(-38px -34px 12px rgba(0, 0, 0, 0.4));

    // @media only screen and (max-width: 600px) {
    //   left: 100px;
    //   top: 30px;
    //   transform: none;
    // }

    h1 {
      // color: #493215;
      color: rgb(179 112 29);
      // background: white;
      padding: 15px 30px;
      font-size: 32px;
      text-align: center;
      max-width: 600px;
      font-family: "Gloria Hallelujah", cursive;
      // filter: drop-shadow(0px 0px 1px rgba(255, 255, 255, 1));
    }
  }
}
