.bubble-chat__chat {
  .chat__body {
    .chat__action-container {
      left: 20px;
      display: flex;
      flex: 0 1;
      // position: absolute;
      bottom: 20px;
      right: 20px;
      width: -webkit-fill-available;
      svg.btn-back {
        margin: auto;
        margin-bottom: 0;
        transform: rotate(90deg);
        cursor: pointer;
        .outter {
          fill: #666;
          stroke: #222;
          stroke-width: 3;
        }
        .inner {
          fill: #999;
          stroke: #ccc;
          stroke-width: 3;
        }
        .arrow-1 {
          fill: #b0cad7;
          stroke: #222;
          stroke-width: 2.5;
        }
        .arrow-2 {
          fill: #c9dfe8;
          stroke: #222;
          stroke-width: 2.5;
        }
        .inner,
        .arrow-1,
        .arrow-2 {
          transition: transform 0.2s ease-in-out;
        }
        &:hover,
        &.clicked {
          .inner,
          .arrow-1,
          .arrow-2 {
            // fill: #999;
            transform: translate(50%, 50%) scale(0.9) translate(-50%, -50%);
          }
        }
        &.clicked {
          .outter {
            fill: #222;
          }
          .inner {
            transform: translate(50%, 50%) scale(0.95) translate(-50%, -50%);
          }
        }
      }

      .chat__action-wrapper {
        display: grid;
        flex: 1;
        text-align: right;
        justify-items: end;

        .chat__action {
          &.option {
            float: right;
            position: relative;
            border-radius: 5px;
            max-width: 75%;
            border: 1px solid #d2bd9d;
            padding: 10px 15px;
            margin: 0px 0px 10px 0px;
            cursor: pointer;
            background-color: white;

            &:hover {
              background-color: #f7f3ec;
            }
            &.selected {
              background-color: #d2bd9d;
            }
            &.hidden {
              opacity: 0;
              visibility: hidden;
            }
            // transition: opacity 0.6s linear;
            transition: all 0.5s ease-in-out;
          }
          &.input {
            display: grid;
            width: -webkit-fill-available;
            margin-left: 10px;
            // visibility: hidden;
          }
        }
      }
    }
  }
}
