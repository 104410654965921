button {
  position: absolute;
  top: 0;
  left: 0;
}

.s-loading {
  .background-container {
    position: relative;
    width: 100%; /* Adjust as needed */
    height: 100vh; /* Adjust as needed */
    background-color: #f0f0f0;
  }

  .svg-background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%; /* 100% width of the container */
    height: 100%; /* 100% height of the container */
    z-index: -1; /* Place it behind the content */
  }

  .logo {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(-30deg);
  }

  .swap-down-svg-wrapper {
    position: absolute;
    bottom: 0;
    // left: 50%;
    right: 0;
    transform: translateX(-20%);
  }
}

.social-media-wrapper {
  &.reel {
    svg.logo {
      width: 160%;
      top: 36%;
      left: 31%;
    }

    svg.loading-letters {
      bottom: 5%;
      width: 120%;
    }
  }
  &.post {
    svg.logo {
      width: 50%;
    }
    svg.loading-letters {
      width: 70%;
    }
  }
  &.desktop {
    svg.logo {
      width: 30%;
    }

    svg.loading-letters {
      width: 50%;
    }
  }
}
