svg.original__monitor {
  .element-text.header.inner,
  .element-text.p {
    font-family: "Quicksand";
  }
}

.element-text {
  user-select: none;
  font-family: "Gloria Hallelujah";

  &.header {
    &.inner {
      // font-weight: bold;
      // -webkit-font-smoothing: antialiased;
      fill: rgb(245, 197, 31);
      // font-variant-caps: petite-caps;
      // filter: drop-shadow(-4px -2px 0px black);
    }
    &.outter {
      // filter: drop-shadow(-1px -1px 1px black);
      font-weight: bolder;
    }
  }

  &.monitor,
  &.monitor_body {
    fill: black;
  }
  &.computer {
    font-family: "Roboto Mono";
    fill: white;
  }
}
