.lyt-scroll {
  // height: 15000px;
  // width: 100vw;
  width: 100%;
  background: #e3e3e3;

  svg.social-media-frame {
    position: fixed;
    z-index: -1;
    rect {
      stroke: rgb(100 100 100);
      stroke-width: 2px;
      fill: transparent;
      z-index: 0;

      &.active {
        fill: white;
        z-index: 1;
      }
    }
  }
  &.social-media-wrapper {
    &.reel {
      .bubble-chat__chat {
        right: 0;
        width: 100%;
        top: 18%;
      }
      .further-action {
        // bottom: 15%;
        right: 18%;
        // transform: translate(50%, 50%) scale(0.8) translate(-50%, -50%);
        // display: grid;

        svg.btn-heart {
          width: 70px;
          height: 70px;
        }
      }
    }
  }

  .sections-wrapper {
    right: 0;
    left: 0;
    margin: auto;
    position: fixed;
    width: 100%;
    transition: top 1s ease-in-out; /* Transition the top property over 1 second with ease timing function */
    border-left: 1px solid #e3dada;
    border-right: 1px solid #e3dada;
    background: rgba(252, 188, 16, 0.3);

    .center-content {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }

    section {
      background: whitesmoke;
      background-image: url("./bg.svg");
      background-repeat: space;
      height: 0;
      width: 100%;
      position: absolute;
      top: 0;
      overflow-y: hidden;
      overflow-x: hidden;
      transition: height 1500ms ease-in-out;

      &.active {
        height: 100%;
      }
    }
  }
}

body {
  overflow: auto;
}
