.language-selector {
  position: absolute;
  right: 20px;
  top: 30px;
  z-index: 99;
  img {
    width: 45px;
  }
  i {
    &.dropdown.icon {
      display: none;
    }
  }
}

.language-selector-setting {
  svg {
    width: 50px;
    fill: #c2c3bd;
    cursor: pointer;
    filter: drop-shadow(3px 5px 2px rgb(0 0 0 / 0.4));
  }
  img {
    position: absolute;
    left: 31px;
    top: 15px;
  }
}
