.animation-progress-bar {
  position: absolute;
  height: 5px;
  top: 10px;
  width: auto;
  border-radius: 5px;
  transform: translate(calc(-50% - 5px), 0);
  left: 50%;
  z-index: 3;

  background-color: #80808087;
  .animation-progress {
    // width: 30%; Calculated trhough the view by the progress
    height: inherit;
    background: lightgray;
    border-radius: 5px;
    // transition: width 100ms ease-in-out;
  }
}
