svg {
  &.loading-letters {
    position: absolute;
    bottom: 0;
    left: 0;
    fill: transparent;
    stroke: black;
    stroke-width: 18px;

    g {
      &.inner-logo {
        transition: transform 300ms ease;
        position: absolute;
        margin: 0px;

        polyline {
          &.active {
            stroke-width: 3px;
            fill: transparent;
          }
        }
      }
    }
    g.loading__letter {
      polyline.loading {
        fill: transparent;
        stroke-width: 32px;
        stroke: #313131;
        stroke-linejoin: round;

        &.active {
          stroke-width: 24px;
          stroke: #313131;
        }
        &.inner {
          stroke: #d6d6d6;
          stroke-width: 21px;
          stroke-linejoin: round;
          opacity: 1;

          &.active {
            stroke: whitesmoke;
            stroke-width: 12px;
          }
        }
      }
      circle.dot {
        stroke-width: 11px;
        fill: whitesmoke;
      }
    }

    polyline {
      opacity: 0.8;
    }

    .active {
      stroke-width: 18px;
      opacity: 1;
    }
    .inner {
      // stroke: saddlebrown;
      stroke: white;
      stroke-width: 10px;
    }
    circle {
      stroke-width: 16px;

      &.inner {
        stroke-width: 8px;
      }
    }
    .dot {
      // stroke-width: 2px;
      // stroke: saddlebrown;
      &circle {
        stroke-width: 0px;

        &.inner {
          stroke-width: 2px;
          stroke: transparent;
        }
      }
    }
  }
}
.loading-letters {
  filter: none;
  g {
    &.inner-logo {
      stroke-width: 1px;
    }
  }
}

svg {
  &.inner-small-triagle {
    // height: fit-content;
    filter: drop-shadow(-3px 0px 0px rgba(0, 0, 0, 0.3));
    // filter: drop-shadow(-5px 0px 0px rgba(0, 0, 0, 0.1));
    g {
      polyline {
        &.bg_right,
        &.bg_left,
        &.bg_bottom {
          fill: rgb(246 201 55);
          // fill: rgb(246 201 55 / 75%);
          stroke: saddlebrown;
        }
        &.triangle {
          fill: rgb(246 201 55 / 75%);
        }
      }
    }
  }
}

svg {
  &.logo {
    &.played {
      .inner-triangle {
        fill: rgb(37 201 35);
      }
    }
  }
}

.designed-arrow,
.loading-letters,
.logo {
  transition: fill 1500ms ease;

  &.shadow {
    filter: drop-shadow(-40px -29px 0px rgba(0, 0, 0, 0.3));
  }

  &.rotate-90 {
    transform: rotate(90deg);
    transition: transform 2s ease;
  }

  &.swap-triangle {
    filter: drop-shadow(0px -20px 0px rgba(0, 0, 0, 0.3)) !important;
  }

  g {
    polyline {
      stroke: saddlebrown;
      transition: fill 1500ms ease;
      cursor: pointer;
      // transition: stroke-width 1500ms ease;
      &.bg {
        // fill: #8f6615; // #8f6615 for fondo oscuro and transparent for fondo claro
        // fill: rgba(4, 244, 4, 0.5); //white; //
        // fill: transparent;
        fill: rgb(205 161 89 / 55%);
      }
      &.bg_botton,
      &.bg_right,
      &.bg_left {
        fill: rgb(248 201 77 / 70%);
      }

      &.bg_triangle-left,
      &.bg_triangle-right,
      &.bg_triangle-bottom {
        fill: transparent;
      }
      &.sqr-triangle {
        fill: rgb(206 154 5 / 92%);
      }
      &.outer-triangle {
        fill: rgb(236 188 52);
      }
      &.inner-triangle {
        fill: rgb(253 210 43 / 83%);
        &.active {
          fill: #25c923ad;
        }
      }
      &.transparent {
        fill: transparent;
      }
    }
  }

  &.inner-sqr {
    filter: none;
    g {
      polyline {
        &.triangle {
          fill: rgb(244 193 79);
          filter: drop-shadow(0px 0px 5px rgba(0, 0, 0, 0.3));
        }
      }
    }
  }
}
