.s-about-me {
  background: transparent;
  z-index: 2;
  $sidevar-width: 800px;

  $header-col: 0px;

  $lyt__header-color: #bca58f;
  $lyt__sidebar-color: #8b5b31;
  $lyt__content-color: linear-gradient(to bottom right, #fabf25, #a3660c); //; #b79b62; //#957772; //#c7ac8e;;

  display: grid;
  grid-template-columns: $sidevar-width auto;
  grid-template-rows: auto;
  text-align: center;
  min-height: 100vh;
  // background: $lyt__content-color;
  max-width: inherit;
  width: 100%;

  // box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.9), 2px 1.77561px 8.87803px rgba(0, 0, 0, 0.25);

  .lyt_page2 {
    &.on-page {
      transform: scale(0.9) rotate3d(26, 59, 6, 21deg); // rotate3d(26, 59, 6, 21deg);
    }
  }

  .lyt__header,
  .lyt__sidebar {
    grid-column-start: 1;
  }

  .lyt__sidebar,
  .lyt__content {
    grid-row-start: 2;
  }

  .lyt__header {
    grid-column-end: 3;
    z-index: 3;
  }

  .lyt__sidebar {
    grid-column-end: 1;
    z-index: 2;
    background-color: $lyt__sidebar-color;
  }
}
