.catalog {
  width: 100vw;
  .catalog_bg {
    .bg-top,
    .bg-bottom {
      width: 100%;
      position: absolute;
    }
    .bg-top {
      background: #c0b7a1;
      height: 100%;
    }
    .bg-bottom {
      background: rgb(83, 36, 19);
      height: 25%;
      bottom: 0;
      background-image: url("./bg_base.svg");
      border-top: 3px solid #7f7b6e;

      // @media only screen and (max-width: 600px) {
      //   height: 15%;
      // }
    }
  }
  .catalog_left {
  }
  .catalog_right {
    display: flex;
    margin: auto;
    height: 100vh;
    width: 100%;
    padding: 40px; // height: 100%;
    padding-left: 0px; // height: 100%;
    .settings {
      width: 100%;
    }
  }
}
