.social-media-wrapper.reel {
  svg#svg-subtitle {
    width: 100%;
    g.svg-image-element.viewer {
      text.element-text.header {
        transform: translate(12px, 18px) rotate(0deg) !important;
      }
    }
  }
}
.social-media-wrapper.desktop {
  svg#svg-subtitle {
    g.svg-image-element.viewer {
      text.element-text.header {
        transform: translate(17px, 22px) rotate(0deg) !important;
      }
    }
  }
}

svg.svg-subtitle {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 75%;

  text,
  textPath {
    font-family: graduate;
  }
  textPath {
    fill: rgb(244 226 212);
  }

  g.svg-image-element.viewer {
    text.element-text.header {
      // transform: translate(17px, 22.400000000000002px) rotate(0deg);
      &.outter {
        filter: drop-shadow(-1px -1px 0px black);
      }
    }
    g {
      &.g-display {
        text.element-text.p.display {
        }
      }
      path {
        &.border {
          // fill: rgb(102 101 104);
          fill: transparent;
        }
        &.display {
          // width: calc(100% - 10px); // -10 omes from the double of the x and y defined in the view.
          // height: calc(100% - 10px);
          stroke: rgb(51 49 49);
          stroke-width: 2px;
          fill: rgb(0 0 0 / 90%);
        }
      }
    }
  }
}
