.social-media-wrapper.instagram {
  &.post {
    #svg-logo {
      width: 62%;
      top: 32%;
      left: 25%;
    }

    #ws__svg__ignacio-front {
      width: 74%;
      right: 0;
      left: auto;
      position: absolute;
      bottom: -18%;
    }

    svg.loading-letters {
      width: 100%;
    }

    .s-instagram-story {
      .background-container {
        position: relative;
        width: 100%; /* Adjust as needed */
        height: 100vh; /* Adjust as needed */
        background-color: #f0f0f0;
      }

      .logo {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) rotate(-30deg);
      }

      .swap-down-svg-wrapper {
        position: absolute;
        bottom: 0;
        // left: 50%;
        right: 0;
        transform: translateX(-20%);
      }
    }
  }
}
