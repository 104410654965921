.swap-down-svg-wrapper {
  &.swap-triangle {
    cursor: pointer;
    text {
      fill: #bb8635;
      // font-family: "Gloria Hallelujah";
      opacity: 0;
      animation: fadeInOutTop 3s linear infinite;
    }

    g {
      // transform: scale(2.5) translate(26px, 20px) rotate(60deg);
      opacity: 0;
      &:nth-child(1) {
        animation: fadeInOutBottom 3s linear infinite;
      }

      // &:nth-child(2) {
      //   animation: fadeInOutMiddle 3s linear infinite;
      // }

      // &:nth-child(3) {
      //   animation: fadeInOutTop 3s linear infinite;
      //   // polyline {
      //   //   &.swap-triangle__outter {
      //   //     fill: red;
      //   //   }
      //   //   &.swap-triangle__inner {
      //   //     fill: red;
      //   //   }
      //   // }
      // }

      polyline {
        stroke: 2px green;
        stroke: burlywood;
        &.swap-triangle__outter {
          fill: #fcbc108a;
        }
        &.swap-triangle__inner {
          fill: #fdd22ba1;
        }
      }
    }
  }
}

@keyframes fadeInOutTop {
  0% {
    opacity: 0.66;
  }
  20% {
    opacity: 0.33;
  }
  40% {
    opacity: 0;
  }
  60% {
    opacity: 0.33;
  }
  80% {
    opacity: 0.66;
  }
  100% {
    opacity: 1;
  }
}

// @keyframes fadeInOutMiddle {
//   0% {
//     opacity: 1;
//   }
//   20% {
//     opacity: 0.66;
//   }
//   40% {
//     opacity: 0.33;
//   }
//   60% {
//     opacity: 0;
//   }
//   80% {
//     opacity: 0.33;
//   }
//   100% {
//     opacity: 0.66;
//   }
// }

@keyframes fadeInOutBottom {
  0% {
    opacity: 0.66;
  }
  20% {
    opacity: 1;
  }
  40% {
    opacity: 0.66;
  }
  60% {
    opacity: 0.33;
  }
  80% {
    opacity: 0;
  }
  100% {
    opacity: 0.33;
  }
}
