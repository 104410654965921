@import url("./Story/index.scss");

.welcome-section {
  padding: 60px 80px;

  svg {
    .g-monitorTabMiro,
    .g-monitorTabMiroDetailTop {
      transition: transform 1000ms ease-in-out;

      &.position-left {
        transform: translate(-150px, 0px);
      }
    }

    .g-monitorTabMiroDetailTop {
      path {
        fill: #20252c; // black tabs VS code
      }
    }
    .g-monitorTabMiro {
      path {
        fill: #272c35; // black background VS code
      }
      text {
        textPath {
          font-family: monospace; // close to the Cascadia Code which is the one used by the VS Code
          fill: #aab2c0; // orange from VS Code
        }
      }
    }

    .g-monitorTabNotion {
      .monitorTabNotion {
        cursor: pointer;
        &:hover {
          stroke: rgb(4 244 4 / 90%);
          fill: rgb(4 244 4 / 30%);
          fill: rgb(93 145 90);
        }
      }
    }

    // text.monitorTabMiro.position-left {
    //   transform: translate(-150px, 0px);
    // }

    .text-tab-miro {
      &.log {
        fill: #272c35; // black background VS code
        fill: #20252c; // black tabs VS code
        fill: #aab2c0; // white tabs VS code
        fill: #f06372; // red tabs VS code
        fill: #1fb9c4; // cyan tabs VS code
        fill: #ebbf6f; // yellow tabs VS code
        fill: #d371e3; // purple tabs VS code
        fill: #45b0f5; // blue tabs VS code
      }

      text.element-text.p.monitorTabMiro {
        font-family: monospace; // close to the Cascadia Code which is the one used by the VS Code
        textPath {
          fill: "#da985c"; // orange from VS Code
          fill: "#8bc570"; // the green from VS Code
        }
      }
    }
  }

  .title-wrapper {
    svg.original__title {
      width: 80%;
      g {
        &.svg-image-element.titleWelcome,
        &.svg-image-element.textWelcome {
          path.titleWelcome,
          path.textWelcome {
            fill: transparent;
            stroke-width: 0;
          }

          text {
            &.element-text.p.titleWelcome,
            &.element-text.p.textWelcome {
              transition: transform 1000ms ease-in-out;
              textPath {
                fill: black;
              }
            }
          }
        }
      }
    }
  }

  svg.ignacio-front {
    transition: width 1000ms ease-in-out;
    width: 35%;

    &.small {
      width: 20%;
    }

    margin: 20px;
    margin-bottom: 0px;
    float: right;
    position: absolute;
    bottom: 0%;
    right: 0;
  }
}

// @keyframes fadeOut {
//   from {
//     opacity: 1;
//     visibility: visible;
//   }
//   to {
//     opacity: 0;
//     visibility: hidden;
//   }
// }
// @keyframes fadeIn {
//   from {
//     opacity: 0;
//     visibility: hidden;
//   }
//   to {
//     opacity: 1;
//     visibility: visible;
//   }
// }

.svg-image-element {
  g {
    &.g-monitorMain {
      opacity: 0;
      transition: opacity 0.5s linear, visibility 0.5s ease-out;
      visibility: hidden;
    }
    &.g-monitorMain.visible {
      opacity: 1;
      visibility: visible;
    }

    &.title {
      fill: transparent;
      stroke: transparent;
    }
  }

  image {
    &.monitorFOTO.character-image {
      // filter: drop-shadow(-5px 2px 4px rgba(0, 0, 0, 1));
    }
  }
}

.sidebar {
  width: 100%;
  padding-top: 25px;
  padding-bottom: 10px;
  z-index: 2;
  position: absolute;
  left: 10px;
  background: whitesmoke;
  .sidebar__logo {
    .logo {
      transform: rotate(-30deg);
    }
  }
}
