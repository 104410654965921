svg.like-btn {
  cursor: pointer;

  &:hover {
    // transform: translate(50%, 50%) rotate(5deg) translate(-50%, -50%);
    transform: rotate(5deg);
  }

  circle {
    fill: transparent;
  }

  &.clicked {
    // circle {
    path.hand {
      fill: rgb(54, 142, 232);
      transform: translate(50%, 50%) scale(0.85) translate(-50%, -50%);
    }
  }
}
