.desktop,
.post {
  .sidebar {
    svg.inner-small-triagle {
      width: 20px;
    }
  }
}

.desktop {
  .sidebar {
    .navbar__item {
      &.selected {
        p.item.text {
          // font-size: x-large;
        }
        // svg.inner-small-triagle {
        //   width: 30px;
        // }
      }
      p.item.text {
        font-size: 1.2rem;
      }
    }
  }
}

.post {
  .sidebar {
    p.item.text {
      // font-size: 1.2rem;
    }
  }
}

.reel {
  .sidebar {
    svg.inner-small-triagle {
      display: none;
    }

    .navbar {
      .item {
        margin-left: 0px;
      }
      .left-items {
        .navbar__item {
          margin-right: 0px;
        }
      }
      .right-items {
        .navbar__item {
          margin-right: 0px;
        }
      }
    }
  }
}

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .left-items {
    display: flex;
    .navbar__item {
      margin-right: 20px;
    }
  }

  .right-items {
    margin-right: 100px;
  }

  .navbar__item {
    display: flex;
    text-decoration: none;
    cursor: pointer;
    margin: auto;
    margin-left: 0px;

    &:hover {
      .item {
        font-weight: bolder;
      }
      svg {
        stroke-width: 2px;
      }
    }

    &.selected {
      .item {
        background: #edd8b6;
        font-weight: bold;
      }
      svg {
        stroke-width: 2px;
      }
    }

    .item {
      margin: auto;
      margin-left: 5px;
      // background: #fff4e2;
      padding: 3px 10px;
      color: #b3711d;
      font-weight: 500;
      user-select: none;
      &.text {
        // font-size: 1.2rem;
        font-family: "Graduate";
      }
    }
  }
}
