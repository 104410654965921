/* @import url("https://fonts.googleapis.com/css2?family=Architects+Daughter&display=swap"); */
@import url("https://fonts.googleapis.com/css2?family=Alegreya&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto+Mono&display=swap");

html {
  font-size: 16px;
}

/* @media (max-width: 900px) {
  html {
    font-size: 14px;
  }
}

@media (max-width: 400px) {
  html {
    font-size: 10px;
  }
} */

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background: #957772; */
  /* background: transparent; */
  overflow: hidden;
  text-rendering: optimizeLegibility;
  background: rgba(252, 188, 16, 0.3);
}
#root {
  display: inline;
}

.App {
  /* max-width: 700px; */

  /* max-width: 900px;
  margin: 0px 120px; */
  height: 100%;
  background: whitesmoke;
}

.fade-in-image {
  animation: fadeIn 1s;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.floating {
  animation-name: floating;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  margin-left: 30px;
  margin-top: 5px;
}
.floating:hover {
  animation-name: none;

  animation-play-state: paused;
}

/* @keyframes floating {
  from {
    transform: translate(0, 0px);
  }
  65% {
    transform: translate(0, 10px);
  }
  to {
    transform: translate(0, -0px);
  }
} */

p,
h1,
h2,
h3,
h4,
h5 {
  /* font-family: "Lato"; */
  font-family: "Gloria Hallelujah";
  /* font-family: "Roboto Mono"; */
  /*
   "Alegreya";
   */
}
