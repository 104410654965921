.settings {
  display: grid;
  margin: auto; //50px;
  padding: 60px 30px;
  // border-radius: 20px;
  // background: #ffffffcf;
  background: #ece7e6;
  text-align: center;
  // filter: drop-shadow(11px -10px 6px rgba(0, 0, 0, 0.4));

  // transform: rotate3d(26, 59, 6, 21deg);

  button {
    cursor: pointer;
    float: right;
    width: fit-content;
  }
}
