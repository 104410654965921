.sty-claude {
  .welcome-section svg .g-monitorTabMiro path {
    fill: #fafaf8 !important;
  }

  svg.original__monitor .element-text.header.inner.monitorMain,
  svg.original__monitor .element-text.p.monitorMain {
    font-family: serif !important;
    font-variant: normal !important;
  }

  svg#ws__svg__original__title text.element-text.header {
    font-family: "Gloria Hallelujah" !important;
    font-variant: normal !important;
  }

  g.svg-image-element.ipad {
    g.g-ipad {
      image.ipad {
        transform: rotate(-5deg) scale(1.5) translate(-120px, -300px) !important;
      }
    }
  }
  g.svg-image-element.computer {
    g.g-computer {
      image.computer {
        transform: rotate(2deg) scale(1.7) translate(-248px, -383px) !important;
      }
    }
  }
}
