.lyt-scroll {
  &.instagram {
    background: #e3e3e3;

    .sections-wrapper {
      background: rgba(252, 188, 16, 0.3);
      top: 50%;
      transform: translate(0%, -50%);
      .s-instagram-story {
        background: white;
        background-repeat: space;
        height: 0;
        width: 100%;
        position: absolute;
        top: 0;
        overflow-y: hidden;
        overflow-x: hidden;
        transition: height 1500ms ease-in-out;

        &.active {
          height: 100%;
        }
      }
    }
  }
}
